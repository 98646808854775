// web component that passes down attributes to sjwc-youtube/sjwc-kollective/etc.

const attributes = {
  type: 'type',
  videoId: 'video-id',
  aspectRatio: 'aspect-ratio',
  playDisallowed: 'play-disallowed',
  fullWidth: 'full-width',
  playerId: 'player-id',
  token: 'token',
  processingCopy: 'processing-copy',
  errorCopy: 'error-copy',
  fullscreenLesson: 'fullscreen-lesson',
  preventWidthCalculation: 'prevent-width-calculation',
};

const ELEMENT_TYPES = new Map([
  ['VIDEO_YOUTUBE', 'sjwc-youtube'],
  ['VIDEO_VIMEO', 'sjwc-vimeo'],
  ['VIDEO_JWPLAYER', 'sjwc-jwplayer'],
  ['VIDEO_WISTIA', 'sjwc-wistia'],
  ['VIDEO_KOLLECTIVE', 'sjwc-kollective'],
  ['VIDEO_BRIGHTCOVE', 'sjwc-brightcove'],
  ['VIDEO_KALTURA', 'sjwc-kaltura'],
  ['VIDYARD', 'sjwc-vidyard'],
  ['SYNTHESIA', 'sjwc-synthesia'],
]);

const defaultWidescreenAspectRatio = '1.7777777777777777';
const widescreenAspectRatios = [defaultWidescreenAspectRatio, '1.76'];

export default class VideoEmbed extends HTMLElement {
  #rootElement;

  #type;

  #videoMaxContainer;

  #videoComponent;

  #flexVideoContainer;

  #aspectRatio;

  #fullWidth;

  #fullscreenLesson;

  #preventWidthCalculation;

  #requiredChildAttributes = [attributes.videoId];

  static get observedAttributes() {
    return Object.values(attributes);
  }

  connectedCallback() {
    this.#type = this.getAttribute(attributes.type);
    this.#aspectRatio = this.getAttribute(attributes.aspectRatio);
    this.#fullWidth = this.hasAttribute('full-width') && this.getAttribute('full-width') !== 'false';
    this.#fullscreenLesson = this.getAttribute('fullscreen-lesson') === 'true';
    this.#preventWidthCalculation = this.getAttribute('prevent-width-calculation') === 'true';

    this.setVideoType();
    this.appendChild(this.#rootElement);
    this.calculateVideoLessonHeight();

    window.addEventListener('DOMContentLoaded', this.calculateVideoLessonHeight.bind(this));
    window.addEventListener('resize', this.calculateVideoLessonHeight.bind(this));
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.calculateVideoLessonHeight.bind(this));

    this.#rootElement.remove();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this.#videoComponent) return;

    this.updateAttribute(name, newValue);
  }

  setVideoType() {
    if (!this.#type) throw new Error('"type" attribute is required');

    const optionalAttributes = [
      attributes.aspectRatio,
      attributes.playDisallowed,
      attributes.playerId,
      attributes.token,
      attributes.fullWidth,
      attributes.processingCopy,
      attributes.errorCopy,
    ];
    let setFlexVideoContainer = false;

    const componentElementTag = ELEMENT_TYPES.get(this.#type);

    if (!componentElementTag) {
      throw new Error(`sjwc-video does not support video type ${this.#type}`);
    }

    const flexVideoContainerTypes = [
      'VIDEO_VIMEO',
      'VIDEO_YOUTUBE',
      'VIDEO_WISTIA',
      'VIDEO_BRIGHTCOVE',
      'VIDEO_KOLLECTIVE',
      'VIDEO_KALTURA',
      'VIDYARD',
      'SYNTHESIA',
    ];

    if (flexVideoContainerTypes.includes(this.#type)) {
      setFlexVideoContainer = true;
    }

    this.validateAttributes();
    this.renderPlayer(
      componentElementTag,
      [...this.#requiredChildAttributes, ...optionalAttributes],
      setFlexVideoContainer
    );
  }

  validateAttributes() {
    this.#requiredChildAttributes.forEach((attr) => {
      if (!this.hasAttribute(attr)) throw new Error(`"${attr}" attribute is required`);
    });
  }

  calculateVideoLessonHeight() {
    if (this.#fullWidth) return;

    const { innerHeight, innerWidth } = window;
    // the flex-video wrapper element may be loated in a client component to SjwcVideo
    const $flexVideoContainer = this.#flexVideoContainer ?? this.#rootElement.querySelector('.flex-video');
    const $header = document.getElementById('header');
    const $lessonMainContainer = document.getElementById('lesson-main');
    const $lessonFooter = document.getElementById('lp-footer');
    const $lessonTop = document.querySelector('.lesson-top');

    // if the video is not in a lesson, we can use the window height to calculate the dimensions of the video
    if ((!$header || !$lessonFooter || !$lessonMainContainer) && !this.#preventWidthCalculation) {
      const maxHeight = innerHeight;

      this.#videoMaxContainer.style.width = `${maxHeight * this.#aspectRatio}px`;

      return;
    }

    if (!$header || !$lessonFooter || !$lessonMainContainer) return;

    $flexVideoContainer.style.marginTop = 0;

    // Departure from non-modular video lessons: we can no longer calculate the height of the video based on the lesson content container height.
    // This is due to there being more than just the video in the lesson content container, so we use the window height instead
    // to get a better approximation of how well the video will fit on the screen, and set the minimum height to 325px (smaller screen sizes are still responsive)
    const headerHeight = $header.offsetHeight;
    const footerHeight = $lessonFooter.offsetHeight;
    const lessonTopHeight = $lessonTop ? $lessonTop.offsetHeight : 0;
    const lessonMainPaddingTop = parseInt(window.getComputedStyle($lessonMainContainer).paddingTop, 10);
    const aboveTheFoldHeight = headerHeight + footerHeight + lessonTopHeight + lessonMainPaddingTop * 2;
    const lessonWindowHeight = innerHeight - aboveTheFoldHeight;
    const heightGutter = 48;
    const aspectRatioWidth = lessonWindowHeight * this.#aspectRatio - heightGutter;

    if (!this.#fullscreenLesson) {
      this.#videoMaxContainer.style.width = '100%';
      return;
    }

    if (this.#fullscreenLesson && aspectRatioWidth > innerWidth) {
      const aspectRatioWidthGreaterThanWindow = aspectRatioWidth > innerWidth;

      this.#videoMaxContainer.style.width = aspectRatioWidthGreaterThanWindow
        ? `${innerWidth - heightGutter}px`
        : `${aspectRatioWidth}px`;

      return;
    }

    this.#videoMaxContainer.style.width = `${aspectRatioWidth}px`;
  }

  renderPlayer(tag, tagAttributes, setFlexVideoContainer) {
    // This method is responsible for passing down attributes to the video provider component for rendering
    this.#rootElement = document.createElement('div');
    this.#rootElement.classList.add(
      'course-fixed-content-video',
      'course-fixed-content-video-flexible-lesson',
      'sjwc-video'
    );

    this.#videoMaxContainer = document.createElement('div');
    this.#videoMaxContainer.classList.add('video-max');

    if (setFlexVideoContainer) {
      const isWidescreen = widescreenAspectRatios.includes(this.#aspectRatio);

      this.#flexVideoContainer = document.createElement('div');
      this.#flexVideoContainer.classList.add('flex-video', ELEMENT_TYPES.get(this.#type));

      const widescreenClassExceptions = [
        'VIDEO_BRIGHTCOVE',
        'VIDEO_VIMEO',
        'VIDYARD',
        'VIDEO_KALTURA',
        'VIDEO_YOUTUBE',
      ];

      this.#flexVideoContainer.classList.toggle(
        'widescreen',
        isWidescreen && !widescreenClassExceptions.includes(this.#type)
      );

      this.#videoComponent = document.createElement(tag);

      tagAttributes.forEach((attr) => {
        if (this.hasAttribute(attr)) this.#videoComponent.setAttribute(attr, this.getAttribute(attr));
      });

      this.#flexVideoContainer.appendChild(this.#videoComponent);
      this.#videoMaxContainer.appendChild(this.#flexVideoContainer);
    } else {
      this.#videoComponent = document.createElement(tag);
      tagAttributes.forEach((attr) => {
        if (this.hasAttribute(attr)) this.#videoComponent.setAttribute(attr, this.getAttribute(attr));
      });

      this.#videoMaxContainer.appendChild(this.#videoComponent);
    }

    this.#rootElement.appendChild(this.#videoMaxContainer);
  }

  updateAttribute(name, newValue) {
    if (newValue === null) {
      this.#videoComponent.removeAttribute(name);
      return;
    }

    if (name === attributes.playDisallowed) this.handlePlayDisallowed(newValue);

    if (name === attributes.fullscreenLesson) {
      this.#fullscreenLesson = newValue;
      this.calculateVideoLessonHeight();
    }

    this.#videoComponent.setAttribute(name, newValue);
  }

  handlePlayDisallowed(newValue) {
    // This method is responsible for disabling tabbing on the video component when the play-disallowed attribute is set to true.
    // Provider-specific video components handle pausing/disabling of the video player.

    if (newValue === 'true') {
      this.#rootElement.setAttribute('tabindex', '-1');
      this.#rootElement.querySelectorAll('*').forEach((el) => {
        if (el.getAttribute('tabindex') === '0') {
          el.setAttribute('tabindex', '-1');
        }
      });
    }
    if (!newValue || newValue === 'false') {
      this.#rootElement.removeAttribute('tabindex');
      this.#rootElement.querySelectorAll('*').forEach((el) => {
        if (el.getAttribute('tabindex') === '-1') {
          el.setAttribute('tabindex', '0');
        }
      });
    }
  }
}

customElements.define('sjwc-video', VideoEmbed);
